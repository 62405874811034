import * as React from "react";
import { withI18nHelper } from '@inwink/i18n/reactcontext';
import { NavLink, Link, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connectwith } from "@inwink/react-utils/decorators/connectwith";
import { Entities } from '@inwink/entities/entities';
import { VisualTheme } from '@inwink/entities/visualtheme';
import { getAbsoluteUrl } from '@inwink/utils/methods/getabsoluteurl';
import { templatedString } from '@inwink/utils/methods/templatedString';
import { HashLink } from 'react-router-hash-link';
import { getEntityCustomFieldTargetLink, getTargetPage } from "./navigation";
import { States } from '../services/services';
import { metadataShareActions } from '../services/appmetadataactions/share';
import type { IInwinkStore } from '../store';
import { UrlServiceContext } from './urlstatecontext';
import CalendarEventGenerator from "@@event/components/calendargenerator.event";
import BadgeDownload from "./badgedownload";
import { useStore } from "react-redux";

export function appLinkIdentifier(link: VisualTheme.IAppLink) {
    if (link) {
        if (link.target) {
            return "target-" + link.target;
        }
        if (link.registration) {
            return "registration-" + link.registration;
        }
        if (link.content) {
            return "content-" + link.content;
        }
    }

    return "";
}

export interface IAppLinkProps {
    id?: string;
    data?: any;
    className?: string;
    enableActiveLink?: boolean;
    link?: VisualTheme.IAppLink;
    target?: string;
    to?: string;
    style?: any;
    ariaLabel?: string;
    tabIndex?: number;
    role?: string;
    title?: string;
    args?: string;
    i18n?: States.i18nState;
    event?: States.IEventState;
    pages?: States.IPagesState;
    context?: VisualTheme.IPageContext;
    onClick?: (arg: React.MouseEvent<any>) => void;
    theme?: Entities.IVisualConfigurationTemplate;
    datacontext?: any;
    i18nHelper?: Entities.i18nHelper;
    urlservice?: States.IAppUrlContext;
    location?: any;
    match?: any;
    history?: any;
    store?: IInwinkStore;
    user?: States.IAppUserState;
    metadataShareActions?: typeof metadataShareActions;
    children?: React.ReactNode;
    linkRef?: React.Ref<HTMLAnchorElement>;
}

function processArgs(args: string, data: any) {
    const res = args;
    if (res) {
        return templatedString(args, data);
    }

    return res;
}

export const protocolRegEx = /^https?:\/\//;
export const anchorRegEx = /^#/;
export interface IAppLinkState {
    initialized: boolean;
}

@connectwith((state: States.IAppState) => {
    return {
        i18n: state.i18n,
        event: state.event,
        community: state.community,
        pages: state.pages
    };
}, (dispatch) => {
    return {
        metadataShareActions: bindActionCreators(metadataShareActions, dispatch)
    };
})
@withI18nHelper()
class AppLinkComponent extends React.Component<IAppLinkProps, IAppLinkState> {
    constructor(props: IAppLinkProps) {
        super(props);
        this.state = {
            initialized: false
        };
    }

    componentDidMount(): void {
        if (!this.state.initialized) {
            this.setState({ initialized: true });
        }
    }

    replacePlaceholders = (subject, props) => {
        let result = subject;
        const placeholderRegex = /\{\{([^}]+)\}\}/g;
        let match;
        while ((match = placeholderRegex.exec(subject)) !== null) {
            const placeholder = match[1];
            const [type, key] = placeholder.split('.');
            if (type === 'event' || type === 'community') {
                const value = props[type] && props[type].detail && props[type].detail[key] || '';
                result = result.replace(match[0], value);
            }
        }
        return result;
    };

    render() {
        const currentLanguageCode = this.props.i18n && this.props.i18n.currentLanguageCode;
        const reduxstore = this.props.store;
        const urlservice = this.props.urlservice;
        const i18n = this.props.i18nHelper;
        if (this.props.to) {
            if (protocolRegEx.test(this.props.to)) {
                return <a
                    ref={this.props.linkRef}
                    rel="noreferrer"
                    id={this.props.id}
                    className={this.props.className || ""}
                    role={this.props.role}
                    href={processArgs(this.props.to, this.props.datacontext)}
                    style={this.props.style}
                    target={urlservice.isWidget ? "_blank" : this.props.target}
                    aria-label={this.props.ariaLabel}
                    tabIndex={this.props.tabIndex}
                    onClick={this.props.onClick}
                    title={this.props.title}
                >
                    {this.props.children}
                </a>;
            }

            return <Link
                id={this.props.id}
                ref={this.props.linkRef}
                className={this.props.className || ""}
                role={this.props.role}
                target={this.props.target}
                to={processArgs(this.props.to, this.props.datacontext)}
                style={this.props.style}
                aria-label={this.props.ariaLabel}
                tabIndex={this.props.tabIndex}
                onClick={this.props.onClick}
                title={this.props.title}
            >
                {this.props.children}
            </Link>;
        }

        if (this.props.link && this.props.link.url) {
            let url: string;
            let target = this.props.link.target ?? "_blank";
            let rel = "noopener";
            if (typeof this.props.link.url !== "string") {
                url = this.props.link.url[currentLanguageCode];
            } else {
                url = this.props.link.url;
            }
            if (this.props.link.url[0] === "#") {
                target = undefined;
                rel = undefined;
            }

            if (this.props.args) {
                if (url.indexOf("?") > 0) {
                    url = url + "&" + processArgs(this.props.args, this.props.datacontext);
                } else {
                    url = url + "?" + processArgs(this.props.args, this.props.datacontext);
                }
            }

            if (url) {
                if (!protocolRegEx.test(url)) {
                    if (!anchorRegEx.test(url)) {
                        url = 'http://' + url;
                    } else {
                        return (
                            <HashLink
                                id={this.props.id}
                                ref={this.props.linkRef}
                                className={this.props.className ? `${this.props.className} link-anchor` : "link-anchor"}
                                role={this.props.role}
                                to={url}
                                style={this.props.style}
                                aria-label={this.props.ariaLabel}
                                tabIndex={this.props.tabIndex}
                                onClick={this.props.onClick}
                            >
                                {this.props.children}
                            </HashLink>
                        );
                    }
                }
            }

            return <a
                ref={this.props.linkRef}
                id={this.props.id}
                className={this.props.className ? `${this.props.className} link-url` : "link-url"}
                role={this.props.role}
                aria-label={this.props.ariaLabel}
                tabIndex={this.props.tabIndex}
                href={(url as string) + (processArgs(this.props.link.args, this.props.datacontext) || "")}
                target={target}
                rel={rel}
                style={this.props.style}
                onClick={(args) => {
                    args.stopPropagation();
                    if (this.props.onClick) {
                        return this.props.onClick(args);
                    }
                    return null;
                }}
                title={this.props.title}
            >{this.props.children}
            </a>;
        } if (this.props.link && this.props.link.mailto) {
            let url = this.props.link.mailto;
            if (typeof this.props.link.mailto !== "string") {
                url = this.props.link.mailto[currentLanguageCode];
            }
            let mailSubject = '';
            if (this.props.link.mailSubject) {
                mailSubject = this.replacePlaceholders(this.props.link.mailSubject, this.props);
            }

            mailSubject = encodeURIComponent(mailSubject);
            const mailtoLink = `mailto:${url as string}?subject=${mailSubject}`;
            return <a
                id={this.props.id}
                ref={this.props.linkRef}
                className={this.props.className ? `${this.props.className} link-mailto` : "link-mailto"}
                rel="noreferrer"
                role={this.props.role}
                aria-label={this.props.ariaLabel}
                tabIndex={this.props.tabIndex}
                href={mailtoLink}
                style={this.props.style}
                target={urlservice.isWidget ? "_blank" : this.props.target}
                onClick={(args) => {
                    args.stopPropagation();
                    if (this.props.onClick) {
                        return this.props.onClick(args);
                    }
                    return null;
                }}
                title={this.props.title}
            >
                {this.props.children}
            </a>;
        } if (this.props.link && this.props.link.doc) {
            let url;
            if (this.props.link.doc[currentLanguageCode] && this.props.link.doc[currentLanguageCode].doc) {
                url = this.props.link.doc[currentLanguageCode].doc.file && this.props.link.doc[currentLanguageCode].doc.file.url;
            } else {
                url = this.props.link.doc.file && this.props.link.doc.file.url;
            }

            const target = "_blank";
            const rel = "noopener";
            if (url) {
                return <a
                    id={this.props.id}
                    ref={this.props.linkRef}
                    className={this.props.className ? `${this.props.className} link-doc` : "link-doc"}
                    role={this.props.role}
                    aria-label={this.props.ariaLabel}
                    tabIndex={this.props.tabIndex}
                    href={(url as string) + (processArgs(this.props.link.args, this.props.datacontext) || "")}
                    target={target}
                    rel={rel}
                    style={this.props.style}
                    onClick={(args) => {
                        args.stopPropagation();
                        if (this.props.onClick) {
                            return this.props.onClick(args);
                        }
                        return null;
                    }}
                    title={this.props.title}
                >{this.props.children}
                </a>;
            }
        } else if (this.props.link
            && (this.props.link.target || this.props.link.content || this.props.link.registration || this.props.link.action)
            && !this.props.link.share) {

            if (this.props.link.action === "downloadBadge") {
                if (!this.state.initialized) {
                    return <React.Fragment />;
                }
                return <React.Suspense fallback={<EmptyLink />}>
                    <BadgeDownload
                        className={this.props.className}
                        role="button"
                        title={this.props.data.buttontitle}
                        type={this.props.link.action}
                        i18n={this.props.i18n}
                    />
                </React.Suspense>;
            }

            if (
                this.props.link.action === "addEventToCalendar" ||
                this.props.link.action === "addSessionsToCalendar"
            ) {
                if (!this.state.initialized) {
                    return <React.Fragment />;
                }
                return <React.Suspense fallback={<EmptyLink />}>
                    <CalendarEventGenerator
                        className={this.props.className}
                        role="button"
                        page={this.props.pages.currentPage[this.props.pages.currentPageId]}
                        title={this.props.data.buttontitle}
                        type={this.props.link.action}
                        i18n={this.props.i18n}
                        event={this.props.event}
                    />
                </React.Suspense>;
            }

            const linkTarget = getTargetPage(
                this.props.link,
                this.props.context,
                reduxstore,
                this.props.data,
                {
                    location: this.props.location,
                    match: this.props.match,
                    history: this.props.history
                },
                this.props.args,
                urlservice,
                this.props.i18nHelper
            );

            if (typeof linkTarget === "string") {
                // let className;
                // if (this.props.theme && this.props.theme.appHeader && this.props.theme.appHeader.activeColor) {
                //     className = "selected bloc-accent";
                // } else {
                //     className = "bloc-accent";
                // }

                const linkTargetUrl = urlservice.pageUrl(linkTarget)
                    + (processArgs(this.props.link.args, this.props.datacontext) || "");

                if (protocolRegEx.test(linkTargetUrl)) {
                    return <a
                        id={this.props.id}
                        ref={this.props.linkRef}
                        className={this.props.className || ""}
                        rel="noreferrer"
                        role={this.props.role}
                        target={urlservice.isWidget ? "_blank" : this.props.target}
                        aria-label={this.props.ariaLabel}
                        tabIndex={this.props.tabIndex}
                        href={linkTargetUrl}
                        style={this.props.style}
                        onClick={this.props.onClick}
                        title={this.props.title}
                    >
                        {this.props.children}
                    </a>;
                }

                return <NavLink
                    id={this.props.id}
                    exact
                    ref={this.props.linkRef}
                    activeClassName={this.props.enableActiveLink ? "selected bloc-accent" : ''}
                    className={this.props.className ? `${this.props.className} link-inwink` : "link-inwink"}
                    role={this.props.role}
                    target={urlservice.isWidget ? "_blank" : this.props.target}
                    aria-label={this.props.ariaLabel}
                    tabIndex={this.props.tabIndex}
                    to={linkTargetUrl}
                    style={this.props.style}
                    onClick={this.props.onClick}
                    title={this.props.title}
                >
                    {this.props.children}
                </NavLink>;
            } if (typeof linkTarget === "function") {
                const onClick = (arg) => {
                    linkTarget(arg);
                    if (this.props.onClick) this.props.onClick(arg);
                };
                return <a
                    id={this.props.id}
                    ref={this.props.linkRef}
                    className={this.props.className || ""}
                    aria-label={this.props.ariaLabel}
                    tabIndex={this.props.tabIndex}
                    role={this.props.role}
                    style={this.props.style}
                    onClick={onClick}
                    title={this.props.title}
                >
                    {this.props.children}
                </a>;
            }
        } else if (this.props.link && this.props.link.share) {
            let url: string = null;
            let shareData;
            if (this.props.link.detail) {
                const detail = getTargetPage(
                    this.props.link,
                    this.props.context,
                    reduxstore,
                    this.props.data,
                    {
                        location: this.props.location,
                        match: this.props.match,
                        history: this.props.history
                    },
                    this.props.args,
                    urlservice
                );
                const url2 = urlservice.pageUrl(detail);
                shareData = {
                    url: getAbsoluteUrl(url2)
                };
            } else {
                shareData = this.props.metadataShareActions.getShare(i18n) as any;
            }

            if (shareData) {
                if (this.props.link.share === "facebook") {
                    url = `https://facebook.com/sharer.php?u=${encodeURIComponent(shareData.url)}`;
                } else if (this.props.link.share === "twitter") {
                    url = `https://twitter.com/intent/tweet?url=${encodeURIComponent(shareData.url)}`;
                } else if (this.props.link.share === "linkedin") {
                    url = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(shareData.url)}`;
                }

                if (url) {
                    const target = "blank";
                    const rel = "noopener";
                    return <a
                        id={this.props.id}
                        ref={this.props.linkRef}
                        className={this.props.className ? `${this.props.className} link-social` : "link-social"}
                        role={this.props.role}
                        href={(url as string) + (processArgs(this.props.link.args, this.props.datacontext) || "")}
                        aria-label={this.props.ariaLabel}
                        tabIndex={this.props.tabIndex}
                        target={target}
                        rel={rel}
                        style={this.props.style}
                        onClick={(args) => {
                            args.stopPropagation();
                            if (this.props.onClick) {
                                return this.props.onClick(args);
                            }
                            return null;
                        }}
                        title={this.props.title}
                    >
                        {this.props.children}
                    </a>;
                }
            }
        } else if (this.props.link && this.props.link.anchor) {
            let targetPage; let targetBloc; let
                linkTarget;
            if (this.props.link.anchor.blocId) {
                targetBloc = `bl-${this.props.link.anchor.blocId}`;
            }
            if (this.props.link.anchor.link) {
                targetPage = getTargetPage(
                    this.props.link.anchor.link,
                    this.props.context,
                    reduxstore,
                    this.props.data,
                    {
                        location: this.props.location,
                        match: this.props.match,
                        history: this.props.history
                    },
                    this.props.args,
                    urlservice,
                    null,
                    true
                );
                linkTarget = urlservice.pageUrl(targetPage) + `#${targetBloc}`;
            } else {
                linkTarget = `#${targetBloc}`;
            }

            return <HashLink
                id={this.props.id}
                ref={this.props.linkRef}
                className={this.props.className ? `${this.props.className} link-anchor` : "link-anchor"}
                role={this.props.role}
                to={linkTarget}
                style={this.props.style}
                aria-label={this.props.ariaLabel}
                tabIndex={this.props.tabIndex}
                onClick={this.props.onClick}
            >
                {this.props.children}
            </HashLink>;
        } else if (this.props.link && this.props.link.live) {
            const linkTarget = urlservice.pageUrl("livesession/" + this.props.link.live);
            return <Link
                id={this.props.id}
                ref={this.props.linkRef}
                to={linkTarget}
                className={this.props.className ? `${this.props.className} link-live` : "link-live"}
                role={this.props.role}
                target={this.props.target}
                style={this.props.style}
                aria-label={this.props.ariaLabel}
                tabIndex={this.props.tabIndex}
                onClick={this.props.onClick}
                title={this.props.title}
            >
                {this.props.children}
            </Link>;
        } else if (this.props.link?.specificField) {
            const linkTarget = getEntityCustomFieldTargetLink(
                this.props.link.specificField,
                this.props.datacontext?.entity,
                this.props.i18nHelper);
            if (linkTarget) {
                return <a
                    id={this.props.id}
                    href={linkTarget}
                    ref={this.props.linkRef}
                    target="_blank"
                    rel="noreferrer"
                    className={this.props.className ? `${this.props.className}` : ""}
                    role={this.props.role}
                    style={this.props.style}
                    aria-label={this.props.ariaLabel}
                    tabIndex={this.props.tabIndex}
                    onClick={(args) => {
                        args.stopPropagation();
                        if (this.props.onClick) {
                            return this.props.onClick(args);
                        }
                        return null;
                    }}
                    title={this.props.title}
                > {this.props.children}</a>;
            } else {
                return "";
            }
        }
        return <a
            id={this.props.id}
            ref={this.props.linkRef}
            className={(this.props.className || "") + ' no-link'}
            role={this.props.role}
            style={this.props.style}
            onClick={this.props.onClick}
            tabIndex={this.props.tabIndex}
            title={this.props.title}
        >
            {this.props.children}
        </a>;
    }
}

function EmptyLink() {
    return <a />;
}
const AppLinkWithRouter: React.ComponentClass<IAppLinkProps> = withRouter(AppLinkComponent as any) as any;

export const AppLink = function AppLink(props: IAppLinkProps) {
    const store = useStore() as any;
    return <UrlServiceContext.Consumer>
        {(urlservice) => <AppLinkWithRouter {...props} store={store} urlservice={urlservice} />}
    </UrlServiceContext.Consumer>;
};
