import * as React from "react";
import { connectwith } from '@inwink/react-utils/decorators/connectwith';
import { checkIfNetworkingAllowed } from "../services/networkingservice";
import { States } from "../services/services";
import { RequireAllowNetworking } from "./messages/requireallownetworking";
import { RequireLogin } from "./messages/requirelogin";
import { RequireRegistration } from "./messages/requireregistration";
import type { IRequireLoginBaseTradsOptions } from "./messages/requirelogin.base";
import { RequireMembership } from "./messages/requiremembership";
import { RequireActiveMembership } from "./messages/requireactivemembership";
import { RequireMembershipLevel } from "./messages/requiremembershiplevel";
import type { VisualTheme } from "@inwink/entities/visualtheme";
import type { IGroupContext } from "@@modules/community/shell/group/communitygroupshell";
import { hasValidMembershipLevel } from "@@modules/community/data";

import "./appuser.restricted.less";

export interface IAppUserRestrictedComponentProps {
    user?: States.IAppUserState;
    i18n?: States.i18nState;
    event?: States.IEventState;
    community?: States.ICommunityState;
    checkAllowNetworking?: boolean;
    page?: States.ICurrentPageState;
    communityGroupContext?: IGroupContext;
    className?: string;
    children?: React.ReactNode;
}

interface IAppUserRestrictedComponentState {
    context: VisualTheme.IPageContext;
}
const communityTradsOptions = {
    requireRegistration: "action.community.requireregistration",
    requireLogin: "action.community.requirelogin",
    requireLoginAndRegistration: "action.community.requireloginandregistration"
};

@connectwith((state: States.IAppState) => {
    return {
        event: state.event,
        community: state.community,
        user: state.user,
        i18n: state.i18n
    };
})
export class AppUserRestrictedComponent extends React.Component<
IAppUserRestrictedComponentProps,
IAppUserRestrictedComponentState
> {

    static defaultProps = {
        className: ""
    };

    constructor(props) {
        super(props);
        this.state = {
            context: props.page?.context
        };
    }

    renderAnonymous(tradsOptions?: IRequireLoginBaseTradsOptions) {
        return (
            // <div className={`appuserrestrictedcomponent anonymoususer 
            // ${this.props.page?.context?.application === "community" ? "commu-noaccess" : ""}`}>
            <div className={`appuserrestrictedcomponent anonymoususer ${this.props.className}`}>
                <RequireLogin
                    {...this.props}
                    configuration={this.props.event?.detail?.configuration || this.props.community?.detail?.configuration}
                    options={{
                        tradsOptions: tradsOptions
                    }}
                />
            </div>
        );
    }

    renderUnregistered(tradsOptions?: IRequireLoginBaseTradsOptions) {
        let conf = null;
        if (this.props.community?.detail) {
            conf = this.props.community.detail.configuration;
        }
        if (this.props.event?.detail) {
            conf = this.props.event.detail.configuration;
        }
        return (
            <div className={`appuserrestrictedcomponent unregistereduser ${this.props.className}`}>
                <RequireRegistration
                    {...this.props}
                    configuration={conf}
                    tradKeyPrefix={tradsOptions?.requireRegistration || "action.requireregistration"}
                    i18n={this.props.i18n}
                />
            </div>
        );
    }

    renderNotMember() {
        return (
            <div className={`appuserrestrictedcomponent notmemberuser ${this.props.className}`}>
                <RequireMembership {...this.props} />
            </div>
        );
    }

    renderOldMembership() {
        return (
            <div className={`appuserrestrictedcomponent oldmemberuser ${this.props.className}`}>
                <RequireActiveMembership {...this.props} />
            </div>
        );
    }

    renderUnallowed() {
        return (
            <div className={`appuserrestrictedcomponent unalloweduser ${this.props.className}`}>
                <RequireMembershipLevel {...this.props} />
            </div>
        );
    }

    renderAllowNetworking() {
        return (
            <div className={`appuserrestrictedcomponent nonallownetworkinguser ${this.props.className}`}>
                <RequireAllowNetworking {...this.props} event={this.props.event} />
            </div>
        );
    }

    render() {
        const user = this.props.user;
        if (this.props.event?.eventid) {
            const currentUser = user?.currentUser?.detail;
            const conf = this.props.event?.detail?.configuration;
            if (!currentUser) {
                return this.renderAnonymous();
            }
            if (!currentUser.isRegistered) {
                return this.renderUnregistered();
            }
            if (this.props.checkAllowNetworking && !checkIfNetworkingAllowed(conf && conf.networking, currentUser, true)) {
                return this.renderAllowNetworking();
            }
        } else if (this.props.community?.communityid) {
            const currentUser = user?.currentUser;
            if (!currentUser) {
                return this.renderAnonymous(communityTradsOptions);
            }
            const isRegister = currentUser?.member?.id;
            if (!isRegister) {
                return this.renderUnregistered(communityTradsOptions);
            }
            const { hasMembership, membershipIsValid } = hasValidMembershipLevel(currentUser?.member);
            if (!hasMembership) {
                return this.renderNotMember();
            }
            if (!membershipIsValid) {
                return this.renderOldMembership();
            }

            const memberAccess = this.state.context?.entity?.memberAccess;
            const groupMember = currentUser.member?.groupMembers?.find(
                (gm) => this.props.communityGroupContext?.groupId === gm.group?.id
            );
            const isCommunityModerator = currentUser.member?.membershipLevel?.configuration?.isModerator;
            const isNotModeratorOrHasNoAccess = (groupMember && !groupMember.isModerator) &&
                (memberAccess && !memberAccess?.some(
                    (ma) => ma.id === currentUser.member?.membershipLevel?.id
                ));
            if (!isCommunityModerator) {
                const isPartialContent = this.props?.page?.context?.entity?._hasPartialContent || 
                    this.state.context?.entity?._hasPartialContent;
                if (this.props.communityGroupContext?.groupId) {
                    const isNotGroupMember = !currentUser.member.groupMembers.some(
                        (gm) => gm.group.id === this.props.communityGroupContext.groupId && gm?.status === "Registered"
                    );
                    if (isNotGroupMember) {
                        return this.renderUnallowed();
                    }
                } else if (isNotModeratorOrHasNoAccess) {
                    return this.renderUnallowed();
                } else if (isPartialContent) {
                    return this.renderUnallowed();
                }
            }
            // if (
            //     (
            //         !currentUser.member.membershipLevel.configuration.groups?.isModerator &&
            //         (memberAccess && !memberAccess?.some(
            //             (ma) => ma.id === currentUser.member.membershipLevel.id
            //         ))
            //     ) ||
            //     (
            //         this.props.customcontext?.group && 
            //         !currentUser.member.groupMembers.some(
            //             (gm) => gm.group.id === this.props.customcontext.group.id
            //         )
            //     )
            // ) {
            //     return this.renderUnallowed();
            // }

            if (this.props.checkAllowNetworking) {
                throw new Error("no implemented");
            }
        } else {
            throw new Error("no valid scope for restricted wrapper");
        }

        return this.props.children;
    }
}
