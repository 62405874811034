import { communityUserBootstrapModule } from '@@routes/appmodules';
import { IInwinkStore, wrapReduxStore } from '@@store/index';
import { Entities } from '@inwink/entities/entities';
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { AppTextLabel, AppLabel } from '../../services/i18nservice';
import { States } from '../../services/services';
import { AppLink } from "../applink";
import { withUrlService } from '../urlstatecontext';

import './requiredaccess.less';

export interface IRequireRegistrationProps {
    configuration?: Entities.IEventDetailConfiguration | States.ICommunityConfiguration;
    i18n?: States.i18nState;
    community?: States.ICommunityState;
    onClick?: () => void;
    history?: any;
    // match?: States.ILocationMatch;
    urlservice?: States.IAppUrlContext;
    // exhibitorId?: string;
    // sessionId?: string;
    registrationAdditionalParams?: IRequireRegistrationAdditionalParams;
    tradKeyPrefix: string;
    store?: IInwinkStore;
    linkRef?: React.Ref<HTMLAnchorElement>;
}

export interface IRequireRegistrationAdditionalParams {
    [key: string]: string
}

@withUrlService()
class RequireRegistrationComponent extends React.Component<IRequireRegistrationProps, any> {
    logout = () => {
        communityUserBootstrapModule().then((mod) => {
            mod.currentUserActions.signout()(this.props.store.dispatch, this.props.store.getState);
        });
    };

    render() {
        return <div className="requiredaccess">
            <div className="message">
                <AppLabel i18n={this.props.tradKeyPrefix} />
            </div>
            {RequireRegistrationActions(this.props)}
            {this.props.community?.communityid ? <a
                role="button"
                className="logout-action"
                onClick={() => this.logout()}
            ><AppTextLabel i18n="menu.logout" /></a> : null}
        </div>;
    }
}

export const RequireRegistration: new (
    props: IRequireRegistrationProps
) => React.Component<IRequireRegistrationProps, any> = withRouter(wrapReduxStore(RequireRegistrationComponent) as any) as any;

export function RequireRegistrationActions(props: IRequireRegistrationProps) {
    // const urlParams = props.match?.params;
    // const sessionId = urlParams?.sessionid || props.sessionId;
    const registration = props.configuration?.registration;
    let btn;
    const arg = typeof window !== "undefined" ? [`_irurl=${encodeURIComponent(window.location.href)}`] : [];

    // if (sessionId) {
    //     arg.push(`registeredsessionid=${sessionId}`);
    // } else if (props.exhibitorId) {
    //     arg.push(`registeredpartnerid=${props.exhibitorId}`);
    // }
    if (props.registrationAdditionalParams && Object.keys(props.registrationAdditionalParams).length > 0) {
        // arg.push(...props.registrationAdditionalParams);
        Object.keys(props.registrationAdditionalParams).forEach((k) => {
            const v = props.registrationAdditionalParams[k];
            arg.push(`${k}=${encodeURIComponent(v)}`);
        });
    }

    if (registration && registration.defaultRegistrationLink) {
        btn = <AppLink
            linkRef={props.linkRef}
            link={registration.defaultRegistrationLink}
            i18n={props.i18n}
            args={arg.join('&')}
            role="button"
            onClick={() => {
                if (props.onClick) {
                    props.onClick();
                }
            }}
        >
            <AppTextLabel i18n={props.tradKeyPrefix + ".button"} />
        </AppLink>;
    } else if ((registration as Entities.IEventDetailConfigurationRegistration)?.defaultRegistrationPage) {
        // old way
        const drp = (registration as Entities.IEventDetailConfigurationRegistration).defaultRegistrationPage;
        btn = <button
            type="button"
            onClick={() => {
                if (props.onClick) {
                    props.onClick();
                }
                props.history.push(props.urlservice.pageUrl(drp));
            }}
        ><AppTextLabel i18n={props.tradKeyPrefix + ".button"} /></button>;
    }
    if (btn) {
        return <div className="actions">
            {btn}
        </div>;
    }

    return null;
}
