import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { withAppTrads } from '@@components/apptrads';
import { Entities } from '@inwink/entities/entities';
import { AppTextLabel, AppLabel } from '../../services/i18nservice';
import { States } from '../../services/services';
import { withUrlService } from '../urlstatecontext';
import { IRequireRegistrationAdditionalParams, RequireRegistrationActions } from './requireregistration';
import { EntityForm, EntityValidationError, IEntityFormState } from '@inwink/entityform';
import { AsyncButton } from '@inwink/buttons';
import { withI18nHelper } from '@inwink/i18n/reactcontext';

import './requiredaccess.less';

export type ChallengeActionInterceptor = (action: 'login' | 'registration', email: string) => Promise<boolean>;
export type ChallengeProvider = (email: string) => Promise<{ exist: boolean }>;

export interface IRequireLoginBaseProps {
    configuration?: Entities.IEventDetailConfiguration | States.ICommunityConfiguration;
    i18n?: States.i18nState;
    i18nHelper?: Entities.i18nHelper;
    onClick?: (action?: 'login' | 'registration') => void;
    options?: IRequiredLoginBaseOptions,
    challengeProvider?: ChallengeProvider;
    challengeActionInterceptor?: ChallengeActionInterceptor;
}

export interface IRequiredLoginBaseOptions {
    registrationAdditionalParams?: IRequireRegistrationAdditionalParams;
    tradsOptions?: IRequireLoginBaseTradsOptions;
    disableRegistrationAction?: boolean;
}

export interface IRequireLoginBaseComponentProps extends IRequireLoginBaseProps {
    onShowLogin: (email: string) => Promise<any>;
    match?: States.ILocationMatch
    history?: any;
    urlservice?: States.IAppUrlContext;
}

interface IRequireLoginBaseComponentState {
    email: string;
    showValidations: boolean;
    registrationNotAvailable?: boolean;
    challenging?: boolean;
}

export interface IRequireLoginBaseTradsOptions {
    requireLogin?: string;
    requireRegistration?: string;
    requireLoginAndRegistration?: string;
    errorNoAccount?: string;
}
@withUrlService()
@withAppTrads()
@withI18nHelper()
class RequireLoginBaseComponent extends React.Component<IRequireLoginBaseComponentProps, IRequireLoginBaseComponentState> {
    formstate: IEntityFormState = null;

    registrationLinkRef = React.createRef<any>();

    constructor(props: IRequireLoginBaseComponentProps) {
        super(props);
        this.state = {
            email: "",
            showValidations: false,
        };
    }

    login = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        if (this.props.onClick) {
            this.props.onClick("login");
        }
        return this.props.onShowLogin(this.state.email);
    };

    renderRegistrationBloc() {
        const options = this.props.options;
        if (options?.disableRegistrationAction) return null;
        const rap = { ...(options?.registrationAdditionalParams ?? {}) };
        if (this.state.email) {
            rap.email = this.state.email;
        }
        const actions = RequireRegistrationActions({
            linkRef: this.registrationLinkRef,
            configuration: this.props.configuration,
            tradKeyPrefix: options?.tradsOptions?.requireRegistration || "action.requireregistration",
            // exhibitorId: options?.exhibitorId,
            history: this.props.history,
            i18n: this.props.i18n,
            // match: this.props.match,
            // sessionId: options?.sessionId,
            registrationAdditionalParams: rap,
            urlservice: this.props.urlservice,
            onClick: () => {
                if (this.props.onClick) {
                    this.props.onClick("registration");
                }
            }
        });
        if (actions) {
            return <div className="registration">
                <div className="message">
                    <AppLabel
                        i18n={options?.tradsOptions?.requireLoginAndRegistration || "action.requireloginandregistration"}
                    />
                </div>
                {actions}
            </div>;
        }

        return null;
    }

    renderOldMode() {
        const options = this.props.options;
        const conf = this.props.configuration;
        const login = (conf && conf.companion && !conf.companion.disableUserCanLogin) ? <div className="login">
            <div className="message">
                <AppLabel i18n={options?.tradsOptions?.requireLogin || "action.requirelogin"} />
            </div>
            <div className="actions">
                <button type="button" className="login-button link-inwink" onClick={this.login}>
                    <AppTextLabel i18n={(options?.tradsOptions?.requireLogin || "action.requirelogin") + ".button"} />
                </button>
            </div>
        </div> : null;
        return <div className="requiredaccess">
            {this.renderRegistrationBloc()}
            {login}
        </div>;
    }

    valueChanged = (entity, state) => {
        this.formstate = state;
        this.setState({
            email: entity.email,
            registrationNotAvailable: false
        });
    };

    challenge = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        arg.stopPropagation();

        if (this.formstate?.isComputing) {
            return;
        }

        if (this.formstate?.validations?.length) {
            this.setState({
                showValidations: true
            });
            return;
        } else {
            return new Promise((resolve, reject) => {
                this.setState({
                    challenging: true
                }, () => {
                    this.props.challengeProvider(this.state.email).then((res) => {
                        const act = () => {
                            if (res.exist) {
                                return this.props.onShowLogin(this.state.email).then(() => {
                                    resolve();
                                });
                            } else if (this.registrationLinkRef?.current) {
                                this.registrationLinkRef.current.click();
                            } else {
                                this.setState({
                                    registrationNotAvailable: true
                                });
                            }
                            resolve();
                        };
                        if (this.props.challengeActionInterceptor) {
                            return this.props.challengeActionInterceptor(res.exist ? "login" : "registration", this.state.email)
                                .then((next) => {
                                    if (next) {
                                        return act();
                                    } else {
                                        // pour hide la modal
                                        if (this.props.onClick) {
                                            this.props.onClick();
                                        }
                                    }
                                }, reject);
                        } else {
                            return act();
                        }
                    }, reject);
                });
            }).finally(() => {
                this.setState({ challenging: false });
            });
        }
    };

    render() {
        const conf = this.props.configuration;
        if ((conf?.companion as any)?.loginMode == "old" || !this.props.challengeProvider) {
            return this.renderOldMode();
        }
        return <div className="requiredaccess challengemode">
            <EntityForm
                displayLanguage={this.props.i18n?.currentLanguageCode}
                entityTemplate={{
                    fields: [{
                        key: "email",
                        type: "mail",
                        isMandatory: true,
                        placeholders: {
                            [this.props.i18n?.currentLanguageCode]: this.props.i18nHelper
                                .translate("action.requireloginchallenge.email.placeholder")
                        },
                    }],
                    languages: [],
                    scopes: {}
                }}
                entityFormTemplate={[{
                    fields: [{
                        key: "email",
                        type: "field",
                        validations: {
                            "noaccount": {
                                [this.props.i18n?.currentLanguageCode]: this.props.i18nHelper
                                    .translate(this.props.options?.tradsOptions?.errorNoAccount
                                        ?? "action.requireloginchallenge.error.noaccount")
                            }
                        }
                    }]
                }]}
                entityValue={{ email: this.state.email }}
                entityName='action.requireloginchallenge'
                forceReadOnly={this.state.challenging}
                onValueChanged={this.valueChanged}
                showValidations={this.state.showValidations}
                onValidationChanged={(formstate) => this.formstate = formstate}
                customValidationsRules={() => {
                    const errors: EntityValidationError[] = [];
                    if (this.state.registrationNotAvailable) {
                        errors.push({
                            error: "noaccount",
                            field: "email",
                            canSave: false
                        });
                    }
                    return errors;
                }}
            />
            <AsyncButton onClick={this.challenge}><AppTextLabel i18n='action.requireloginchallenge.send' /></AsyncButton>
            <div style={{ display: "none" }}>{this.renderRegistrationBloc()}</div>
        </div>;
    }
}

export const RequireLoginBase: new (
    props: IRequireLoginBaseComponentProps
) => React.Component<IRequireLoginBaseComponentProps, any> = withRouter(RequireLoginBaseComponent as any) as any;
