import * as React from 'react';
import { useStore } from 'react-redux';
import { ItemTemplate as _ItemTemplate, ItemTemplateProps } from '@inwink/itemtemplate';
import { registerItemTemplatePart, registerAsyncItemTemplatePart } from '@inwink/itemtemplate/templatecatalog';
import { States } from 'services/services';
import { AppLink } from '../applink';
import * as AppModules from '../../routes/appmodules';
import { ItemTemplatePicture, ItemTemplateUserPicture, ItemTemplateLogoPicture } from './picturetemplates';
import { EntityActions } from './entityactions';
import { LoginButton } from './loginbutton';
import { LogoutButton } from "./logoutbutton";
import { InwinkLink } from './inwinklink';
import { InwinkFileLink } from './inwinkfilelink';
import { UserRestrictedTemplate } from './userrestricted';
import { DateRangeTemplate } from './daterange';
import { DisplayTimeZoneDate } from "../displaytimezonedate";
import { InwinkImageTemplatePart } from './inwinkimage';
import { ProductItemPrice } from '@@modules/order/steps/products/product.item.price';
import { ShowEndDate } from '@@modules/order/steps/products/product.item.enddate';
import { ShowAvailabilityInfos } from '@@modules/order/steps/products/product.item.quota';
import { ProductItemQuantity } from '@@modules/order/steps/products/product.item.quantity';
import { VideoBackgroundTemplatePart } from './videobackground';

import './itemtemplate.less';

registerItemTemplatePart("inwinkimage", InwinkImageTemplatePart);
registerItemTemplatePart("videobackground", VideoBackgroundTemplatePart);
registerItemTemplatePart("picture", ItemTemplatePicture);
registerItemTemplatePart("userpicture", ItemTemplateUserPicture);
registerItemTemplatePart("logo", ItemTemplateLogoPicture);
registerItemTemplatePart("pictureimg", (props) => React.createElement(ItemTemplatePicture, {...props, useImgTag: true}));
registerItemTemplatePart("userpictureimg", (props) => React.createElement(ItemTemplateUserPicture, {...props, useImgTag: true}));
registerItemTemplatePart("logoimg", (props) => React.createElement(ItemTemplateLogoPicture, {...props, useImgTag: true}));
registerItemTemplatePart("entityactions", EntityActions);
// registerItemTemplatePart("tags", TagsList);
// registerItemTemplatePart("fieldlabel", (props) => React.createElement(TagsList, { ...props, showOnlyLabels: true }));
registerItemTemplatePart("inwinklink", InwinkLink);
registerItemTemplatePart("inwinkfilelink", InwinkFileLink);
registerItemTemplatePart("loginbutton", LoginButton);
registerItemTemplatePart("logoutbutton", LogoutButton);
registerItemTemplatePart("timezonedate", DisplayTimeZoneDate);
registerItemTemplatePart("daterange", DateRangeTemplate);
registerItemTemplatePart("userrestricted", UserRestrictedTemplate);
registerItemTemplatePart("orderprice", ProductItemPrice);
registerItemTemplatePart("orderenddate", ShowEndDate);
registerItemTemplatePart("orderquota", ShowAvailabilityInfos);
registerItemTemplatePart("orderquantity", ProductItemQuantity);




registerAsyncItemTemplatePart("entitydetailv2actions", (n, d, s) => AppModules.blocContentActionsModule(d, s));
registerAsyncItemTemplatePart("itemtemplateactions", (n, d, s) => AppModules.blocContentActionsModule(d, s));
registerAsyncItemTemplatePart("bloccontentactions", (n, d, s) => AppModules.blocContentActionsModule(d, s));
registerAsyncItemTemplatePart("videoitem", (n, d, s) => AppModules.videoItemModule(d, s));
registerAsyncItemTemplatePart("actionqueuestatus", () => import("./actionqueuestatus").then((mod) => {
    registerItemTemplatePart("actionqueuestatus", mod.ActionQueueStatus);
    return mod;
}));
registerAsyncItemTemplatePart("sociallinks", (n, d, s) => AppModules.entityDetailV2Module(d, s));
registerAsyncItemTemplatePart("entityshare", (n, d, s) => AppModules.entityDetailV2Module(d, s));
registerAsyncItemTemplatePart("fieldlabel", (n, d, s) => AppModules.entityDetailV2Module(d, s));
registerAsyncItemTemplatePart("tags", (n, d, s) => AppModules.entityDetailV2Module(d, s));
registerAsyncItemTemplatePart("picturegallery", (n, d, s) => {
    return AppModules.pictureGalleryItemModule(d, s);
});

export interface IItemTemplateProps extends ItemTemplateProps {
    i18n?: States.i18nState;
    customContent?: any;
    onRecoChanged?: (id: string) => void;
    onDataUpdate?: (data: any) => void;
    onStatusChanged?: (data: any) => void;
}

export const ItemTemplateControl = (props: IItemTemplateProps) => {
    const store = useStore();

    let customContent;
    if (props.customContent) {
        customContent = React.createElement(props.customContent, props);
    }
    return <>{React.createElement(_ItemTemplate, Object.assign({}, props, { linkComponent: AppLink, store }))}{customContent}</>;
};

// eslint-disable-next-line import/no-mutable-exports
export let InwinkItemTemplate = ItemTemplateControl;
export function hookInwinkItemTemplate(callback) {
    InwinkItemTemplate = callback(ItemTemplateControl);
}

export const ItemTemplate = (props: IItemTemplateProps) => {
    const store = useStore();
    return React.createElement(_ItemTemplate, Object.assign({}, props, { linkComponent: AppLink, store }));
};
